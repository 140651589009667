import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useScrollSection, ScrollingProvider } from "react-scroll-section";
import { useLanguageContext } from "./components/Context";

export const Layout = ({ children }) => {
  const { language, setLanguage } = useLanguageContext();
  const location = useLocation();

  const [contactState, setContactState] = useState(false);
  const [menuOpenState, setMenuOpenState] = useState(false);
  console.log(location.pathname);

  const header = {
    "/":
      (language == "es" && "etnomusicólogo y músico") ||
      "ethnomusicologist and musician",
    "/imagenes": (language == "es" && "imagenes") || "images",
    "/textos": (language == "es" && "textos") || "texts",
    "/textos/la-danza-de-todolella":
      (language == "es" && "textos: la danza de todolella") ||
      "texts: la danza de todolella",
    "/sonidos": (language == "es" && "sonidos") || "sounds",
    "/pretextos": (language == "es" && "pretextos") || "pretexts",

    "/pretextos/riganti":
      (language == "es" && "pretextos: adolfo riganti") ||
      "pretexts: adolfo riganti",
  };

  useEffect(() => {
    if (window.innerWidth > 812) {
      setMenuOpenState(true);
    }
    const handleResize = () => {
      if (window.innerWidth > 812) {
        setMenuOpenState(true);
      }
    };
    // Attach event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <h4 className="nav-btn" onClick={() => setMenuOpenState(!menuOpenState)}>
        {(menuOpenState && "X") || "MENU"}
      </h4>
      <div id="content-box">
        <ScrollingProvider>
          <div className="header-left">
            <div className="title">
              <Link to={"/"}>
                <h1>ramón pelinski</h1>
                <h2>
                  {Object.keys(header).includes(location.pathname)
                    ? header[location.pathname]
                    : header["/"]}
                </h2>
              </Link>
            </div>
            <div className="content">{children}</div>
          </div>
          {menuOpenState && (
            <nav>
              <div className="menu">
                <div
                  className="elements"
                  onClick={() => {
                    if (window.innerWidth > 812) {
                      // if desktop, always open
                      setMenuOpenState(true);
                    } else {
                      // if mobile, toggle
                      setMenuOpenState(!menuOpenState);
                    }
                  }}
                >
                  <Element
                    {...{
                      url: "/",
                      linkname: (language === "es" && "inicio") || "home",
                    }}
                  />
                  <Element
                    {...{
                      url: "/textos",
                      linkname: (language === "es" && "textos") || "texts",
                    }}
                  />
                  <Element
                    {...{
                      url: "/imagenes",
                      linkname: (language === "es" && "imágenes") || "images",
                    }}
                  />
                  <Element
                    {...{
                      url: "/sonidos",
                      linkname: (language === "es" && "sonidos") || "sounds",
                    }}
                  />
                  <Element
                    {...{
                      url: "/pretextos",
                      linkname:
                        (language === "es" && "pretextos") || "pretexts",
                    }}
                  />
                </div>

                <div className="bottom-nav">
                  <div
                    className="row"
                    onClick={() => {
                      if (language === "es") setLanguage("en");
                      else setLanguage("es");
                    }}
                  >
                    <h4> {(language === "es" && "English") || "Español"}</h4>
                  </div>
                  <div
                    className="row"
                    onClick={() => setContactState(!contactState)}
                  >
                    <h4> {(language === "es" && "Contacto") || "Contact"}</h4>
                    {contactState && (
                      <h5>Pilar Ramos --{">"}(mpramoslopez@gmail.com)</h5>
                    )}
                  </div>
                </div>
              </div>
            </nav>
          )}
        </ScrollingProvider>
      </div>
    </>
  );
};

const Element = ({ url, linkname }) => {
  const location = useLocation();
  return (
    <Link to={url} className="element">
      <div className="element-content">
        <h2>{linkname}</h2>
        {url == location.pathname && (
          <Submenu {...{ page: url.split("/")[1] }} />
        )}
      </div>
    </Link>
  );
};

const Submenu = ({ page }) => {
  const { language, _ } = useLanguageContext();

  const sections = {
    textos: [
      {
        section: useScrollSection("libros"),
        title: (language === "es" && "> Libros") || "> Books",
      },

      {
        section: useScrollSection("articulos"),
        title:
          (language === "es" && "> Selección de artículos") ||
          "> Selected articles",
      },
      {
        section: useScrollSection("discos"),
        title: (language === "es" && "> Discos") || "> Albums",
      },
    ],

    imagenes: [
      {
        section: useScrollSection("homenaje"),
        title: (language === "es" && "> Homenaje") || "> Tribute",
      },
      {
        section: useScrollSection("danza"),
        title: (language === "es" && "> Danza de espadas") || "> Sword dance",
      },
      { section: useScrollSection("inuit"), title: "> Inuit" },
      { section: useScrollSection("tango"), title: "> Tango" },
      { section: useScrollSection("todolella"), title: "> Todolella" },
    ],
    sonidos: [
      {
        section: useScrollSection("rp"),
        title:
          (language === "es" && "> Selección de piezas musicales de R.P.") ||
          "> Selected musical pieces by R.P.",
      },
      ,
      {
        section: useScrollSection("tangox4"),
        title:
          (language === "es" && "> Tangos porteños ejecutados por Tangox4") ||
          "> Tangos porteños performed by Tangox4",
      },
      ,
      {
        section: useScrollSection("angora"),
        title:
          (language === "es" && "> Fragmentos de la tangópera Angora Matta") ||
          "> Fragments of the tangópera Angora Matta",
      },

      {
        section: useScrollSection("paisaje"),
        title: (language === "es" && "> Paisaje sonoro") || "> Sound landscape",
      },
    ],
  };
  return (
    <>
      {sections[page]?.map((e, i) => (
        <h4
          className="submenu-item"
          key={i}
          onClick={e.section.onClick}
          selected={e.section.selected}
        >
          {" "}
          {e.title}
        </h4>
      ))}
    </>
  );
};
