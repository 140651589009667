import React from "react";
import { Link } from "react-router-dom";
import { Section } from "react-scroll-section";

import { useLanguageContext } from "../components/Context";

import danzaPdf from "../public/documents/La-Danza-de-Todolella-Entrada.pdf";
import embdimentPdf from "../public/documents/Embodiment-and-musical-experience-2005.pdf";
import tangoNomadePdf from "../public/documents/Tango-nomade-Una-metafora-de-la-globalizacion-2008.pdf";
import entrevistaPdf from "../public/documents/Con-los-sentidos-y-la-menta-bien-alertas-Entrevista-El-Oído-Pensante1.pdf";

export const TextosPage = () => {
  const { language, _ } = useLanguageContext();

  return (
    <>
      <div id="textos-container">
        <Section id="libros">
          <>
            <h2>{(language === "es" && "Libros") || "Books"}</h2>
            <ul>
              <li>
                <em>
                  La Danza de Todolella. Memoria, historia y usos políticos de
                  la danza de espadas.
                </em>{" "}
                Valencia: Instituto Valenciano de la música. 2011.
                <nobr>
                  <Link
                    to={"/textos/la-danza-de-todolella"}
                    title="La Danza de Todolella."
                  >
                    {" "}
                    {(language === "es" && "Leer fragmento ") || "Read excerpt"}
                  </Link>{" "}
                  {(language === "es" && "ó") || "or"}
                  <a href={danzaPdf} target="_blank" rel="noopener">
                    {" "}
                    {(language === "es" && " Descargar entrada ") ||
                      "Download entry"}
                  </a>
                </nobr>
              </li>
              <li>
                <em>
                  Invitación a la etnomusicología. Quince fragmentos y un tango.
                </em>{" "}
                Madrid: Akal, 2000.
              </li>
              <li>
                <em>
                  La musique des Inuit du Caribou. Cinq perspectives
                  méthodologiques.
                </em>{" "}
                Montréal: Les Presses de l’Université de Montréal. 1981.
              </li>
              <li>
                <em>
                  Die Weltliche Musik Spaniens am Anfang des 17. Jahrhunderts.
                </em>{" "}
                Tutzing: Schneider. 1971.
              </li>
            </ul>
          </>
          <h4>
            {(language === "es" && "Co-autor y compilador de:") ||
              "Co-author and editor:"}
          </h4>
          <ul>
            <li>
              <em>El tango nómade.</em> Buenos Aires: Corregidor, 2000.
            </li>
            <li>
              <em>Presencia del pasado en un cancionero castellonense.</em>{" "}
              Castellón: Universitat Jaume I. 1997.
            </li>
            <li>
              <em>Tango nomade.</em> Montréal: Tryptique. 1995.
            </li>
            <li>
              <em>Inuit Songs from Eskimo Point</em> (con Luke Suluk y Lucy
              Amarook). Ottawa: Musées Nationaux du Canada. 1979.
            </li>
          </ul>
        </Section>
        <Section id="articulos">
          <h2>
            {" "}
            {(language === "es" && "Selección de artículos") ||
              "Article selection"}
          </h2>
          <ul>
            <li>
              Con los sentidos y la mente bien alertas.{" "}
              <a href={entrevistaPdf} target="_blank" rel="noopener">
                {" "}
                {(language === "es" && "Leer entrevista") || "Read interview"}
              </a>
            </li>
            <li>
              “Grata la voz del agua. Paisajes sonoros de la Alhambra”.{" "}
              {(language === "es" && "En") || "In"}{" "}
              <em>Centro Virtual Cervantes</em> 2010:
              <a
                title={`“Grata la voz del agua. Paisajes sonoros de la Alhambra”. ${
                  (language === "es" && "En") || "In"
                } Centro Virtual Cervantes 2010.`}
                href="http://cvc.cervantes.es/artes/paisajes_sonoros/p_sonoros02/pelinski/pelinski_01.htm"
                target="_blank"
                rel="noopener"
              >
                {" "}
                {(language === "es" && "Visitar web") || "Visit website"}
              </a>
            </li>
            <li>
              “El oído alerta: modos de escuchar el entorno sonoro”.{" "}
              {(language === "es" && "En") || "In"}{" "}
              <em>Centro Virtual Cervantes</em> 2009:{" "}
              <a
                title={`“El oído alerta: modos de escuchar el entorno sonoro”. ${
                  (language === "es" && "En") || "In"
                }  Centro Virtual Cervantes 2009.`}
                href="http://cvc.cervantes.es/artes/paisajes_sonoros/p_sonoros01/pelinski/pelinski_01.htm"
                target="_blank"
                rel="noopener"
              >
                {" "}
                {(language === "es" && "Visitar web") || "Visit website"}
              </a>
            </li>
            <li>
              “Tango nómade. Una metáfora de la globalización.”{" "}
              {(language === "es" && "En") || "In"} Lencina, Teresita / Garcia
              Brunelli, Omar / Salton, Ricardo.(comp.){" "}
              <em>
                Escritos sobre tango. {(language === "es" && "En") || "In"} el
                Río de la Plata y en la diáspora.
              </em>{" "}
              Buenos Aires, Centro‘feca Ediciones, 2009, p. 65-128.{" "}
              <a href={tangoNomadePdf} target="_blank" rel="noopener">
                {" "}
                {(language === "es" && "Leer artículo") || "Read article"}
              </a>
            </li>
            <li>
              “Tango nômade. Uma metáfora da globalização.”{" "}
              {(language === "es" && "En") || "In"} Valente, Heloísa de A. D.
              (org).{" "}
              <em>
                “Dónde estás, corazón?” O tango “no” Brasil; o tango “do”
                Brasil.
              </em>{" "}
              São Paulo: Via Lettera; CNPq. 2011.
            </li>
            <li>
              “Piazzolla – Entre tango et fugue: La recherche d’une identité
              stylistique.” {(language === "es" && "En") || "In"}{" "}
              <em>Analyse Musicale.</em> nº 48, Septembre 2003, p. 38-54.
              (Versión castellana en: O. García Brunelli (comp.):{" "}
              <em>Estudios sobre la obra de Astor Piazzolla.</em> Buenos Aires:
              Gourmet Musical. 2008).
            </li>
            <li>
              “Sobre teoría y práctica en la investigación musical.{" "}
              <em>Ad usum musicae scientiae</em>”.{" "}
              {(language === "es" && "En") || "In"}{" "}
              <em>Jentilbaratz – Cuadernos de Folklore</em>, nº 9, Donostia,
              2007, p. 273-291.
            </li>
            <li>
              “Embodiment and Musical Experience.”{" "}
              {(language === "es" && "En") || "In"} TRANS-
              <em>Revista Transcultural de Música</em>, Nº9, 2005.{" "}
              <nobr>
                <a href={embdimentPdf} target="_blank" rel="noopener">
                  {" "}
                  {(language === "es" && "Leer artículo") || "Read article"}
                </a>{" "}
                {(language === "es" && "ó") || "or"}{" "}
                <a
                  href="http://www.sibetrans.com/trans/a178/embodiment-and-musical-experience"
                  target="_blank"
                  rel="noopener"
                >
                  {" "}
                  {(language === "es" && "Visitar web") || "Visit website"}
                </a>
              </nobr>
            </li>
          </ul>
        </Section>
        <Section id="discos">
          <h2>{(language === "es" && "Discos") || "Albums"}</h2>
          <ul>
            <li>
              <em>La Todolella. Música Tradicional</em>, Generalitat Valenciana,
              1991.
            </li>
            <li>
              <em>Musique et chants des Inuit: Eskimo Point y Rankin Inlet</em>,
              Montréal, UMMUS, 1990.
            </li>
            <li>
              <em>Tanguissimimo</em>, ERM 318-1932, 1987.
            </li>
            <li>
              <em>Chants des Inuit du Caribou</em>, CBC, Northern Service, 1984.
            </li>
          </ul>
        </Section>
      </div>
    </>
  );
};
