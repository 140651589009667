import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Favicon from "react-favicon";
import "./App.scss";

import { Layout } from "./Layout";
import { LanguageContextProvider } from "./components/Context";

import { HomePage } from "./pages/Home.page";
import { ImagenesPage } from "./pages/Imagenes.page";
import { TextosPage } from "./pages/Textos.page";
import { LaDanzaPage } from "./pages/LaDanza.page";
import { SonidosPage } from "./pages/Sonidos.page";
import { PretextosPage } from "./pages/Pretextos.page";
import { RigantiPage } from "./pages/Riganti.page";

import favicon from "./public/images/Ramon-Pelinski.jpg";

export const App = () => (
  <>
    <Favicon url={favicon} />
    <Router>
      <LanguageContextProvider>
        <Layout>
          <Switch>
            <Route path="/" exact component={HomePage} />
            <Route path="/imagenes" exact component={ImagenesPage} />
            <Route path="/textos" exact component={TextosPage} />
            <Route
              path="/textos/la-danza-de-todolella"
              exact
              component={LaDanzaPage}
            />
            <Route path="/sonidos" exact component={SonidosPage} />
            <Route path="/pretextos" exact component={PretextosPage} />
            <Route path="/pretextos/riganti" exact component={RigantiPage} />
          </Switch>
        </Layout>
      </LanguageContextProvider>
    </Router>
  </>
);
