import React from "react";
import { Section } from "react-scroll-section";
import ReactPlayer from "react-player";

import { useLanguageContext } from "../components/Context";

import s1 from "../public/sounds/1-El-Expatriado.mp3";
import s2 from "../public/sounds/2-Neurotango.mp3";
import s3 from "../public/sounds/3-Une-image.mp3";
import s4 from "../public/sounds/4-Tangoral.mp3";
import s5 from "../public/sounds/5-Canco-del-qui-espera.mp3";
import s6 from "../public/sounds/6-Adios-pampa-mia.mp3";
import s7 from "../public/sounds/7-Caminito.mp3";
import s8 from "../public/sounds/8-Canaro-en-Paris.mp3";
import s9 from "../public/sounds/9-La-comparsita.mp3";
import s10 from "../public/sounds/10-Tanguito-1.mp3";
import s11 from "../public/sounds/11-Tanguito-2.mp3";
import s12 from "../public/sounds/12-Valsecito.mp3";
import s13 from "../public/sounds/13-La-despedida.mp3";
import s14 from "../public/sounds/14-La-bruja.mp3";
import s15 from "../public/sounds/15-Finale-II.mp3";
import s16 from "../public/sounds/16-Grata-la-voz-del-agua.mp3";

export const SonidosPage = () => {
  const { language, _ } = useLanguageContext();

  const nota = {
    es: (
      <ol start="16">
        <AudioItem
          {...{
            url: s16,
            title: "Grata la voz del agua",
            desc: "Música acuática de La Alhambra (2007).",
          }}
        />

        <h4>
          Nota sobre <em>Grata la voz del agua</em>
        </h4>
        <p>
          <em>Grata la voz del agua</em> es título tomado de un poema de J. L.
          Borges, escrito a raíz de su visita a la Alhambra en 1977. Esta pieza
          está concebida como un diálogo entre sonidos acuáticos de la Alhambra
          y la elaboración electrónica de algunos de ellos, realizada por el
          compositor de música electrónica Medín Peirón, a quien expreso aquí mi
          agradecimiento.
        </p>
        <p>En la configuración de la pieza juegan un papel destacado:</p>
        <ul>
          <li>
            el número 12, número simbólico de la Alhambra: la pieza dura
            exactamente 12' y contiene una serie de pasajes sonoros cuyo número
            de apariciones está regulado por múltiplos de 12.
          </li>
          <li>
            el Preludio nº 15 ("la gota de agua") de Chopin, centrado sobre el
            eje de un ostinato que contiene 776 instancias de una 'gota'. Algo
            semejante sucede con <em>Grata la voz del agua</em> que discurre
            enteramente en torno a un incansable goteo.
          </li>
        </ul>
        <p>
          Abundan los pasajes sonoros lentos que invitan al oyente a escuchar
          dentro de los sonidos, como iniciación a la escucha ecológica que nos
          revela un mundo sonoro inhabitual en el medio urbano. El ruido de las
          voces humanas se ha 'naturalizado' al punto de considerarlo como parte
          integral del paisaje sonoro de la Alhambra.
        </p>
        <p>
          La pieza va desde la escucha de los distintos "laberintos sonoros" de
          la Alhambra hasta su conglomerado —una especie de "panauditivo"— y, en
          distorsionado espejo, retorna a la diferenciación de las fuentes
          sonoras (2007).
        </p>
      </ol>
    ),
    en: (
      <ol start="16">
        <AudioItem
          {...{
            url: s16,
            title: "Grata la voz del agua",
            desc: "Acquatic music from La Alhambra (2007).",
          }}
        />

        <h5>
          A note on <em>Grata la voz del agua</em>
        </h5>
        <p>
          The title <em>Grata la voz del agua</em> is taken from a poem by J. L.
          Borges, written after his visit to the Alhambra in 1977. This piece is
          conceived as a dialogue between the acquatic sounds of the Alhambra
          and the electronic processing of some of them, done by the electronic
          music composer Medín Perión, to whom I here express my gratitude.
        </p>
        <p> A few elements play a key role in the piece configuration:</p>
        <ul>
          <li>
            the number 12, the Alhambra's symbolic number: the piece lasts
            exactly 12' and contains a series of soundscapes that appear in
            multiples of 12.
          </li>
          <li>
            Chopin's Prelude No. 15 ("Raindrop"), centered around an ostinato's
            axis that contains 776 instances of a 'raindrop'. Something similar
            happens with <em>Grata la voz del agua</em>, which revolves entirely
            around a tireless dripping.
          </li>
        </ul>
        <p>
          Slow soundscapes that invite the hearer to listen inside the sounds
          abound, as an initiation to ecological listening that reveals us an
          unusual sonic world in the urban medium. The noise of human voices has
          been 'naturalised' to the point of being considered as an integral
          part of the soundscape of the Alhambra.
        </p>
        <p>
          This piece goes from the listening of the various Alhambra's "sound
          labryinths" to its conglomerates –a sort of "panauditive"– and,
          through a distorted mirror, returns to the differentation of sound
          sources (2007).
        </p>
      </ol>
    ),
  };

  return (
    <div id="sonidos-page">
      <Section id="rp">
        <h4>
          {(language === "es" && "Selección de piezas musicales de R. P.:") ||
            "R.P. musical pieces selection"}
        </h4>
        <ol>
          <AudioItem
            {...{
              url: s1,
              title: "El Expatriado",
              desc: `${
                (language === "es" && "En el CD") || "In the CD"
              } espresso de Quartango, nº 6. JUST 84-`,
            }}
          />
          <AudioItem
            {...{
              url: s2,
              title: "Neurotango",
              desc: `${
                (language === "es" && "En el CD") || "In the CD"
              } espresso de Quartango, nº 10. JUST 84-`,
            }}
          />
          <AudioItem
            {...{
              url: s3,
              title: "Une image",
              desc:
                (language === "es" &&
                  "(1985), Tx4, Festival internacional de jazz, Montreal (en vivo)") ||
                "(1985), Tx4, Jazz International Festival, Montreal (live)",
            }}
          />
          <AudioItem
            {...{
              url: s4,
              title: "Tangoral",
              desc:
                (language === "es" &&
                  "(1998), Joseph Petric (acordeón) con Bellows and Brass (en vivo).") ||
                "(1998), Joseph Petric (accordion) with Bellows and Brass (live).",
            }}
          />
          <AudioItem
            {...{
              url: s5,
              title: "Cançó del qui espera",
              desc: `(2003), ${
                (language === "es" && "letra de") || "lyrics"
              } Roger Costa-Pau; Quartet Gerió, CD Stringendo edicions, Ref. 1.001.`,
            }}
          />
        </ol>
      </Section>
      <Section id="tangox4">
        <h4>
          {(language === "es" && "Tangos porteños ejecutados por Tangox4") ||
            "Tangos porteños played by Tangox4"}
        </h4>
        <ol start="6">
          <AudioItem
            {...{
              url: s6,
              title: "Adiós pampa mía",
              desc: `(M. Mores), (Tudor singers + Tx4 (A. Bornstein, R. Larrea, R. Gosselin y R. Pelinski), Montréal, Pollack Hall, 1981 ${
                (language === "es" && "(en vivo)") || "(live)"
              }.`,
            }}
          />
          <AudioItem
            {...{
              url: s7,
              title: "Caminito",
              desc: `(J. de D. Filiberto), (A. Penón, bando, L. Grinhauz, vln, M. Leiter, cb y R. Pelinski, pn). ${
                (language === "es" &&
                  "Festival Internacional de Jazz, Montreal, Sala Spectrum, 1985 (en vivo)") ||
                "International Jazz Festival, Montreal, Spectrum Hall, 1985 (live)"
              }.`,
            }}
          />
          <AudioItem
            {...{
              url: s8,
              title: "Canaro en París",
              desc: "(A. Scarpino y J. Caldarella), idem. ",
            }}
          />
          <AudioItem
            {...{
              url: s9,
              title: "La comparsita",
              desc: "(G. Matos Rodríguez), idem.",
            }}
          />
        </ol>
      </Section>
      <Section id="angora">
        <h4>
          {language === "es" && "Fragmentos de la tangópera"} Angora Matta{" "}
          {language === "en" && " tangópera excerpts"} ({" "}
          {(language === "es" && "Libreto de") || "Libretto by"} M. Savigliano.
          Buenos Aires, 2002)
        </h4>
        <ol start="10">
          <AudioItem {...{ url: s10, title: "Tanguito 1", desc: "" }} />
          <AudioItem {...{ url: s11, title: "Tanguito 2", desc: "" }} />
          <AudioItem {...{ url: s12, title: "Valsecito", desc: "" }} />
          <AudioItem {...{ url: s13, title: "La despedida", desc: "" }} />
          <AudioItem {...{ url: s14, title: "La bruja", desc: "" }} />
          <AudioItem {...{ url: s15, title: "Finale II", desc: "" }} />
        </ol>
      </Section>
      <Section id="paisaje">
        <h4> {(language === "es" && "Paisaje sonoro") || "Soundscape"} </h4>
        {nota[language]}
      </Section>
    </div>
  );
};

const AudioItem = ({ url, title, desc }) => (
  <li>
    <em>{title}.</em> {desc}
    <ReactPlayer
      url={url}
      className="audio-player"
      controls={true}
      height={40}
      width={"50vw"}
    />
  </li>
);
