import React from "react";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";

import { Image } from "../components/Image";
import { useLanguageContext } from "../components/Context";

import i1 from "../public/images/Adolfo-Riganti-con-amigos-en-Bariloche-1958.jpg";
import entrevista from "../public/sounds/Entrevista-con-Osvaldo-Pugliese-1-3-1984.mp3";
import rigantiPdf from "../public/documents/Adolfo-Riganti-Espiral-1992.pdf";

export const PretextosPage = () => {
  const { language, _ } = useLanguageContext();

  return (
    <div id="pretextos-page">
      <Image
        {...{
          source: i1,
          caption:
            (language === "es" &&
              "Adolfo Riganti con amigos en Bariloche (1958)") ||
            "Adolfo Riganti with friends in Bariloche (1958)",
        }}
      />
      <ul>
        <li>
          {" "}
          ADOLFO RIGANTI (1921-1999) <em>Espiral </em>(1992).
          <Link
            to={"/pretextos/riganti"}
            title="ADOLFO RIGANTI – Espiral (1992)"
          >
            {" "}
            {(language === "es" && "Leer fragmento ") || "Read excerpt"}{" "}
          </Link>{" "}
          {(language === "es" && "ó") || "or"}
          <a href={rigantiPdf} target=" _blank" rel="noopener">
            {" "}
            {(language === "es" && "Descargar pdf ") || "Download pdf"}
          </a>
        </li>
        <li>
          {" "}
          {(language === "es" &&
            "Entrevista con OSVALDO PUGLIESE (1905 - 1995) el día 1 de Marzo de 1984.") ||
            "Interview with OSVALDO PUGLIESE (1905 - 1995) on March 1st 1984."}
          <ReactPlayer
            url={entrevista}
            className="audio-player"
            controls={true}
            height={40}
            width={"50vw"}
          />
        </li>
      </ul>
    </div>
  );
};
