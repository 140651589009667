import React from "react";
import { Section } from "react-scroll-section";

import { Image } from "../components/Image";
import { useLanguageContext } from "../components/Context";

import h1 from "../public/images/Clase-de-O-Messiaen-1960-61.jpg";
import h2 from "../public/images/Maurice-Merleau-Ponty-1908-1961.jpg";
import h3 from "../public/images/Roman-Ingarden-1893-1970.jpg";
import h4 from "../public/images/Thrasybulos-Georgiades.jpg";

import d1 from "../public/images/Obejo-Danza-de-espadas.jpg";
import d2 from "../public/images/Todolella-Danza-de-espadas-2010.jpg";
import d3 from "../public/images/Zurich-Ms-F-27-fol-62a.jpg";
import d4 from "../public/images/Nuremberg-1600.jpg";

import i1 from "../public/images/Igloolik.jpg";
import i2 from "../public/images/Irqaluit-1_1.jpg";
import i3 from "../public/images/Irqaluit-2-Nunavut-Canada.jpg";
import i4 from "../public/images/Mediodia-en-Igloolik.jpg";
import i5 from "../public/images/Nutaraluk.jpg";
import i6 from "../public/images/Tautugni-Rankin-Inlet.jpg";

import t1 from "../public/images/A-Piazzolla-con-F-Suarez-y-R-P.jpg";
import t2 from "../public/images/Arturo-Penon.jpg";
import t3 from "../public/images/Astor-Piazzolla-y-P-Ziegler-Montreal-1985.jpg";
import t4 from "../public/images/En-concierto-con-Tango-x-3-Montreal-Festival-de-Jazz-1986.jpg";
import t5 from "../public/images/En-concierto-con-Tx4-Montreal-1984.jpg";
import t6 from "../public/images/Tango-x-4-1981.jpg";

import e1 from "../public/images/Eremitorio-1.jpg";
import e2 from "../public/images/Eremitorio.jpg";
import e3 from "../public/images/Musicos-de-la-Danza-guerrera-La-Todolella-1992.jpg";

export const ImagenesPage = () => {
  const { language, _ } = useLanguageContext();

  return (
    <>
      <SectionContainer
        title={(language === "es" && "homenaje") || "tribute"}
        id="homenaje"
      >
        <Image
          {...{
            source: h1,
            caption:
              (language === "es" && "Clase de O. Messiaen (1960-61)") ||
              "O. Messiaen's class (1960-61)",
          }}
        />
        <Image
          {...{ source: h2, caption: "Maurice Merleau-Ponty (1908-1961)" }}
        />
        <Image {...{ source: h3, caption: "Roman Ingarden (1893-1970)" }} />
        <Image
          {...{ source: h4, caption: "Thrasybulos Georgiades (1907-1977) " }}
        />
      </SectionContainer>
      <SectionContainer
        title={(language === "es" && "Danza de espadas") || "Sword dance"}
        id="danza"
      >
        <Image
          {...{ source: d1, caption: "Degollada del patatú, Obejo (2009)" }}
        />
        <Image
          {...{
            source: d2,
            caption:
              (language === "es" && "Danza de Todolella (2010)") ||
              "Todolella sword dance (2010)",
          }}
        />
        <Image {...{ source: d3, caption: "Zürich, Ms. F 27, fol.62a " }} />
        <Image {...{ source: d4, caption: "Nuremberg, 1600 " }} />
      </SectionContainer>
      <SectionContainer title="Inuit" id="inuit">
        <Image {...{ source: i1, caption: "Igloolik" }} />
        <Image {...{ source: i2, caption: "Irqaluit" }} />
        <Image {...{ source: i3, caption: "Irqaluit (Nunavut, Canada) " }} />
        <Image
          {...{
            source: i4,
            caption:
              (language === "es" && "Mediodía en Igloolik ") ||
              "Miday in Igloolik",
          }}
        />
        <Image
          {...{
            source: i5,
            caption: "Elizabet Nutaraluk (Eskimo Point, 1976) ",
          }}
        />
        <Image {...{ source: i6, caption: "Tautungi (Rankin Inlet, 1975) " }} />
      </SectionContainer>
      <SectionContainer title="Tango" id="tango">
        <Image
          {...{
            source: t1,
            caption:
              (language === "es" && "A. Piazzolla con F. Suárez y R.P. ") ||
              "A. Piazzolla with F. Suárez and R.P. ",
          }}
        />
        <Image {...{ source: t2, caption: "Arturo Penón " }} />
        <Image
          {...{
            source: t3,
            caption:
              (language === "es" &&
                "Astor Piazzolla y P. Ziegler (Montreal, 1985)  ") ||
              "Astor Piazzolla and P. Ziegler (Montreal, 1985)  ",
          }}
        />
        <Image
          {...{
            source: t4,
            caption:
              (language === "es" &&
                "En concierto con Tango x 3 (Montreal, Festival de Jazz 1986) ") ||
              "Concert with Tango x 3  (Montreal, Jazz Festival 1986)",
          }}
        />
        <Image
          {...{
            source: t5,
            caption:
              (language === "es" && "En concierto con Tx4 (Montreal, 1984) ") ||
              "Concert with Tx4 (Montreal, 1984)",
          }}
        />
        <Image {...{ source: t6, caption: "Tango x 4 (1981) " }} />
      </SectionContainer>
      <SectionContainer title="Todolella" id="todolella">
        <Image {...{ source: e1, caption: "Eremitorio S. Cristóbal " }} />
        <Image
          {...{
            source: e2,
            caption:
              (language === "es" && "Eremitorio S. Cristóbal (Foto Carbó) ") ||
              "Eremitorio S. Cristóbal (Photo by Carbó) ",
          }}
        />
        <Image
          {...{
            source: e3,
            caption:
              (language === "es" &&
                "Músicos de la Danza guerrera (La Todolella, 1992) ") ||
              "Sword dance musicians (La Todolella, 1992) ",
          }}
        />
      </SectionContainer>
    </>
  );
};

const SectionContainer = ({ children, title, id }) => (
  <Section id={id} className="photo-section">
    <h2>{title}</h2>
    <div className="photo-content">{children}</div>
  </Section>
);
