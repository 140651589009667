import React from "react";
import { useLanguageContext } from "../components/Context";
import profilePic from "../public/images/Ramon-Pelinski.jpg";

export const HomePage = () => {
  const { language, _ } = useLanguageContext();

  const content = {
    es: (
      <>
        <p>
          Ramón Pelinski (1932-2015), etnomusicólogo y músico, nació en Corpus
          (Misiones), Argentina.
        </p>
        <p>
          Realizó estudios de piano y composición y se licenció en Filosofía en
          Córdoba. Luego trabajó como Director de educación de la Provincia de
          Río Negro (Patagonia) antes de viajar a Europa. En París fue alumno de
          O.Messiaen en el Conservatorio y de J.Chailley en la Sorbonne además
          de asistir a las clases de M.Merleau - Ponty en el Collège de France.
          Después de estudiar estética con W.Tatarkiewicz (Varsovia) y
          R.Ingarden (Cracovia), realizó sus estudios de musicología en la
          Universidad Ludwig Maximilian de Munich donde se doctoró con una tesis
          sobre el Cancionero Claudio de la Sablonara (1969) dirigida por
          Thr.Georgiades. En Munich fundó y dirigió un conjunto dedicado a la
          difusión de música española del Renacimiento, y participó como
          intérprete en los conciertos de la serie Neue Musik. Entre 1969 y 1972
          fue coordinador musical de la Exposición Olímpica Weltkulturen und
          Moderne Kunst.
        </p>
        <p>
          En 1973 emigró a Canadá, donde fue profesor titular y luego
          catedrático de musicología en las universidades de Ottawa (1973- 1976)
          y Montreal (1976-1995). Paralelamente a su actividad docente realizó
          trabajo etnográfico entre los Inuit del Ártico Central. En Montreal
          fundó el primer conjunto canadiense de tango porteño del que fue
          pianista y arreglista durante doce años. En 1994 fue designado miembro
          correspondiente de la Academia del Tango de Argentina.
        </p>
        <p>
          En España fue investigador invitado en el CSIC, docente de
          etnomusicología en la Universidad de Granada, coordinador del Diploma
          de etnomusicología en la Universidad de Valencia (1999- 2001), y
          cofundador y presidente de la SIbE (Sociedad Ibérica de
          Etnomusicología, 1995-1999). Fue además docente invitado en las
          Universidades de Poznañ, de California (UCLA) y del Master de
          etnomusicología online de la Universidad de Maryland. En 1995 fundó
          TRANS —la primera revista musical online de España— cuya dirección
          cedió a la SIbE en 2000. Ese mismo año recibió el Premio Nacional de
          Folklore Agapito Marazuela. En noviembre de 2002 se estrenó en el
          Teatro Alvear de Buenos Aires su tangópera Angora Matta sobre libreto
          de M. Savigliano y bajo la dirección musical de G. Gandini. R.
          Pelinski coordinó los trabajos de rehabilitación del eremitorio S.
          Cristóbal en Todolella (Castellón, España). En esa misma localidad
          realizó trabajo etnográfico, cuyos resultados se presentan en su
          último libro: La Danza de Todolella. Memoria, historia y usos
          políticos de la danza de espadas (2011).
        </p>
      </>
    ),
    en: (
      <>
        <p>
          Ramón Pelinski (1932-2015), ethnomusicologist and musician, was born
          in Corpus (Misiones), Argentina.
        </p>
        <p>
          Ramón studied piano and composition and graduated in Philosophy in
          Córdoba. He then worked as Director of Education in the Province of
          Río Negro (Patagonia) before traveling to Europe. In Paris, he studied
          with O. Messiaen at the Conservatoire, J. Chailley at the Sorbonne
          University, and Maurice Merleau-Ponty at the Collège de France. As
          well as studying aesthetics with W. Tatarkiewicz (Warsaw) and R.
          Ingarden (Krakov), he also studied musicology at the Ludwig
          Maximillian University in Munich, where he completed his PhD on the
          Claudio de la Sablonara Cancionero (1969), under the supervision of
          Thr. Georgiades. In Munich, he founded and directed an ensemble
          dedicated to disseminating Renaissance Spanish music and participated
          as a performer in the Neue Musik concert series. Between 1969 and
          1972, he was the musical coordinator of the Weltkulturen und Moderne
          Kunst Olympic Exhibition.
        </p>
        <p>
          In 1973 he emigrated to Canada, where he was a Senior Lecturer and
          then Professor of Musicology at Ottawa University (1973-1976) and
          Montreal University (1976-1995). In parallel to his teaching activity,
          he conducted ethnographic work among the Inuit people of the Central
          Arctic. In Montreal, he founded the first ensemble of tango porteño,
          in which he was a pianist and arranger for twelve years. In 1994, he
          was appointed corresponding member of the Academia del Tango de
          Argentina.
        </p>
        <p>
          In Spain, he was an invited researcher at CSIC (Spanish National
          Research Council), ethnomusicology lecturer at Granada University,
          coordinator of the Ethnomusicology Diploma at the Valencia University
          (1999-2001), and co founder and president of the SIbE (Ethnomusicology
          Iberian Society, 1995-1999). He also was an invited lecturer at Poznań
          University, UCLA, and of the Ethnomusicology online Master's degree at
          Maryland University. In 1995, he founded TRANS –the first online music
          journal in Spain–, whose direction he handed over to SIbE in 2000. In
          that same year, he received the Spanish National Folklore Prize
          Agapito Marazuela. In November 2002, his tangópera Angora Matta was
          premiered at the Alvear Theater in Buenos Aires with M. SAvigliano's
          libretto and G. Gandini's musical direction. R. Pelinski coordinated
          the rehabilitation works of the S. Cristóbal hermitage in Todolella
          (Castellón, Spain). In that same town, he conducted ethnographic work,
          whose results are presented in his last book: La Danza de Todolella.
          Memoria, historia y usos políticos de la danza de espadas (2011).
        </p>
      </>
    ),
  };

  return (
    <div id="home-page">
      <img src={profilePic} />
      {content[language] || ""}
    </div>
  );
};
