import React from "react";

export const RigantiPage = () => (
  <div id="riganti-page">
    <h4>
      ADOLFO RIGANTI (1921-1999) &#8211; <em>Espiral </em>(1992).
    </h4>
    <p>
      <em>Hay una imagen que penetra mis noches.</em>
      <br />
      <em> Su entorno obsesivo se dilata</em>
      <br />
      <em> y roe los bordes de mi sueño:</em>
    </p>
    <p>
      <em>Veo un punto que irrumpe en la planicie</em>
      <br />
      <em> de espacio tiempo infinito.</em>
      <br />
      <em> Después</em>
      <br />
      <em> Una curva que asciende</em>
      <br />
      <em> y gira a la vez en su eje.</em>
      <br />
      <em> ¿Qué nido invisible desata</em>
      <br />
      <em> un revuelo ominoso de alas?</em>
    </p>
    <p>
      <em>Pradera argentina en invierno.</em>
      <br />
      <em> Soñoliento despertar</em>
      <br />
      <em> y un mundo intacto que llega.</em>
      <br />
      <em> Veo en él una casa</em>
      <br />
      <em> absorta en un halo dichoso</em>
      <br />
      <em> de trinos, de follaje y de flores.</em>
      <br />
      <em> Hay primavera que tejen</em>
      <br />
      <em> una incansable y frondosa avenida</em>
      <br />
      <em> que conduce a la escuela.</em>
      <br />
      <em> En horas sin tiempo</em>
      <br />
      <em> siento aún su fragancia.</em>
    </p>
    <p>
      <em>Lentas</em>
      <br />
      <em> se desperezan otras imágenes:</em>
      <br />
      <em> una mañana radiante</em>
      <br />
      <em> y mi mano en el collar fiel de un perro.</em>
      <br />
      <em> Huía de casa con ímpetu.</em>
      <br />
      <em> Pasaban calles y gente.</em>
      <br />
      <em> Llegaban</em>
      <br />
      <em> las últimas casas del pueblo.</em>
      <br />
      <em> Pasaban también.</em>
      <br />
      <em> Después</em>
      <br />
      <em> avanzaba sin pausa por el largo camino.</em>
      <br />
      <em> Sol y lejanía.</em>
      <br />
      <em> E inmensos maizales</em>
      <br />
      <em> flanqueaban mi ruta.</em>
    </p>
    <p>
      <em>Iglesia de pueblo.</em>
      <br />
      <em> La más temprana misa.</em>
      <br />
      <em> Fugaz campanilleo</em>
      <br />
      <em> y contrabajo de oraciones.</em>
      <br />
      <em> Quieta penumbra del alba.</em>
      <br />
      <em> De pronto</em>
      <br />
      <em> escala de fulgores que se enciende</em>
      <br />
      <em> en altos ventanales.</em>
      <br />
      <em> Por ella descendían</em>
      <br />
      <em> multicolores ángeles y santos,</em>
      <br />
      <em> corderos y patriarcas,</em>
      <br />
      <em> y demonios sulfurosos.</em>
      <br />
      <em> Clarinada del sol</em>
      <br />
      <em> que ponía de pie</em>
      <br />
      <em> a un mundo mágico.</em>
      <br />
      <em> La vida era entonces</em>
      <br />
      <em> el lento gotear</em>
      <br />
      <em> de una difusa ternura.</em>
    </p>
    <p>
      <em>De pronto, en un instante,</em>
      <br />
      <em> todo se detuvo:</em>
      <br />
      <em> mi tenue, naciente paraíso,</em>
      <br />
      <em> el surco confiado de los días</em>
      <br />
      <em> y en las noches</em>
      <br />
      <em> la barca segura de mi sueños.</em>
      <br />
      <em> Una línea negra</em>
      <br />
      <em> Trizó el espejo encantado.</em>
      <br />
      <em> ¿Cómo el tiempo y la muerte</em>
      <br />
      <em> llegaron tan pronto?</em>
    </p>
    <p>
      <em>Un niño había muerto</em>
      <br />
      <em> y yo lo conocía&#8230;</em>
      <br />
      <em> “Solo se ha ido</em>
      <br />
      <em> —decía mi madre—</em>
      <br />
      <em> Estará en el cielo</em>
      <br />
      <em> Y seguirá jugando.</em>
      <br />
      <em> Algún día</em>
      <br />
      <em> lo encontrarás allí.”</em>
    </p>
    <p>
      <em>¡Oh el soplo helado</em>
      <br />
      <em> que secó todos mis senderos!</em>
      <br />
      <em> Erguido en un vigor que no era mío</em>
      <br />
      <em> grité convulso</em>
      <br />
      <em> a la madre desolada:</em>
      <br />
      <em> “¡No! No quiero irme.</em>
      <br />
      <em> ¡No al cielo, no!</em>
      <br />
      <em> Quiero quedarme aquí,</em>
      <br />
      <em> para siempre, aquí en la tierra.”</em>
    </p>
    <p>
      <em>Duró esa angustia mucho tiempo.</em>
      <br />
      <em> Y ya nunca fui el mismo.</em>
      <br />
      <em> Y ya nada fue lo mismo.</em>
      <br />
      <em> Ahora luces, ruido y movimiento</em>
      <br />
      <em> de una gran ciudad.</em>
      <br />
      <em> Estudios, amigos&#8230;</em>
      <br />
      <em> Música y poesía en vastos relámpagos.</em>
      <br />
      <em> Pero siempre</em>
      <br />
      <em> un ascua errante de pasión</em>
      <br />
      <em> rodaba de lo alto</em>
      <br />
      <em> hacia un callado fondo de dolor.</em>
      <br />
      <em> Rutinas grises.</em>
      <br />
      <em> Tedio , furores y acritud.</em>
      <br />
      <em> Aparentemente</em>
      <br />
      <em> nada se movía.</em>
      <br />
      <em> Pero viajaba en la angustia</em>
      <br />
      <em> –ala nocturna–</em>
      <br />
      <em> por ciudades dormidas,</em>
      <br />
      <em> hurañas e insidiosas,</em>
      <br />
      <em> que ollaza con pie inseguro.</em>
      <br />
      <em> ¡Y de pronto caía,</em>
      <br />
      <em> caía en el confín del silencio!</em>
      <br />
      <em> Tu clamor helado en el alma</em>
      <br />
      <em> Soledad</em>
      <br />
      <em> me ensordecía&#8230;</em>
      <br />
      <em> Era en la última hora.</em>
      <br />
      <em> La hora en que muere la noche</em>
      <br />
      <em> con un gran aleteo postrero</em>
      <br />
      <em> de oscura mariposa.</em>
    </p>
    <p>
      <em>Larga ceniza de tiempo.</em>
      <br />
      <em> Y un día</em>
      <br />
      <em> en el perdido baldío de un barrio</em>
      <br />
      <em> alguien reencendió en mi</em>
      <br />
      <em> la brasa agonizante.</em>
      <br />
      <em> Llegó alguien,</em>
      <br />
      <em> y fue, a la vez,</em>
      <br />
      <em> troquel y acicate,</em>
      <br />
      <em> llama y transparencia.</em>
      <br />
      <em> Cada aurora de esos días</em>
      <br />
      <em> alumbraba un mundo siempre otro.</em>
      <br />
      <em> Alguna vez</em>
      <br />
      <em> no sin sorpresa</em>
      <br />
      <em> vi reencenderse con luz nueva</em>
      <br />
      <em> los mágicos vitrales de mi infancia:</em>
      <br />
      <em> los que allí vivían</em>
      <br />
      <em> en reino de hadas</em>
      <br />
      <em> eran ahora fuerzas reales</em>
      <br />
      <em> en la vida y en el arte.</em>
    </p>
    <p>
      <em>Un día entre los días</em>
      <br />
      <em> la suave brisa del destino</em>
      <br />
      <em> llevó mi nave por ruta solitaria.</em>
      <br />
      <em> A tientas mi paso indagaba</em>
      <br />
      <em> doctrinas y rituales</em>
      <br />
      <em> místicos y ascesis.</em>
      <br />
      <em> Llegaron</em>
      <br />
      <em> en larga procesión</em>
      <br />
      <em> cavilosos metafísicos.</em>
      <br />
      <em> Cosmovisiones como ríos</em>
      <br />
      <em> se perdían en un mar desconocido.</em>
      <br />
      <em> Y volvía siempre</em>
      <br />
      <em> a la misma orilla.</em>
      <br />
      <em> Sentado allí interminables horas,</em>
      <br />
      <em> atento al mar insomne,</em>
      <br />
      <em> arrojaba hacia lo hondo,</em>
      <br />
      <em> una y otra vez,</em>
      <br />
      <em> mis silenciosas redes.</em>
    </p>
    <p>
      <em>Un día dejé todo</em>
      <br />
      <em> y fui a pedir ayuda.</em>
      <br />
      <em> Maestros de ojos extraños</em>
      <br />
      <em> recogieron mi deseo casi agónico:</em>
      <br />
      <em> dilatar la vida aquí en la tierra.</em>
      <br />
      <em> Sopló un viento mágico</em>
      <br />
      <em> en mesetas heladas.</em>
      <br />
      <em> ¡Oh, transmutaciones!</em>
      <br />
      <em> Materia que renace en alquímico fulgor.</em>
      <br />
      <em> tomos y espíritu.</em>
      <br />
      <em> Dimensiones del tiempo</em>
      <br />
      <em> escalonadas en orbes paralelos.</em>
      <br />
      <em> Visiones</em>
      <br />
      <em> de arcángeles que pasan</em>
      <br />
      <em> de un mundo hacia otro mundo</em>
      <br />
      <em> por desiertos pasadizos.</em>
      <br />
      <em> Desollado en esa ascesis</em>
      <br />
      <em> caía de pronto</em>
      <br />
      <em> en mi humana y triste realidad:</em>
      <br />
      <em> Nada de dioses.</em>
      <br />
      <em> Ahora el que volvía</em>
      <br />
      <em> Era el mismo que partió&#8230;</em>
    </p>
    <p>
      <em>Pero oculta y persistente</em>
      <br />
      <em> en todos los rincones</em>
      <br />
      <em> de esa cansada paz</em>
      <br />
      <em> el ansia renovada de partir</em>
      <br />
      <em> tañía con apagados martillos</em>
      <br />
      <em> un gong de terciopelo.</em>
      <br />
      <em> A veces se ahondaba la noche</em>
      <br />
      <em> y de su oquedad confusa ascendía</em>
      <br />
      <em> una nueva voz implorante;</em>
      <br />
      <em> “¡Que llegue un alba incandescente</em>
      <br />
      <em> y disipe este sosiego de neblinas!</em>
      <br />
      <em> No el descanso</em>
      <br />
      <em> sino buscar y transformarme</em>
      <br />
      <em> es lo que anhelo.</em>
      <br />
      <em> Porque no eres un límite</em>
      <br />
      <em> Inmensidad callada.</em>
      <br />
      <em> No eres un muro</em>
      <br />
      <em> ante el cual caer de rodillas,</em>
      <br />
      <em> sino un más allá</em>
      <br />
      <em> que nunca deja de serlo</em>
      <br />
      <em> por más que nos acerquemos.”</em>
    </p>
    <p>
      <em>Hubo una compañera de ruta.</em>
      <br />
      <em> Llegó casi sin darme cuenta.</em>
      <br />
      <em> Pero quedó a mi lado mucho tiempo</em>
      <br />
      <em> como una lámpara suave.</em>
      <br />
      <em> Buscamos juntos</em>
      <br />
      <em> con extraños mapas en la mano</em>
      <br />
      <em> a través de mediodías radiantes</em>
      <br />
      <em> o tristes crepúsculos.</em>
      <br />
      <em> Alguna vez atravesamos</em>
      <br />
      <em> la cámara mortuoria</em>
      <br />
      <em> con la gran momia de oro.</em>
      <br />
      <em> Rugosa oscuridad de teólogos</em>
      <br />
      <em> allí medía y juzgaba,</em>
      <br />
      <em> condenaba y bendecía.</em>
      <br />
      <em> Se oían sus voces:</em>
      <br />
      <em> “Dios es esto. llega hasta aquí.</em>
      <br />
      <em> Puede esto. No quiere aquello. Siente así.”</em>
      <br />
      <em> Y mientras tanto, allá afuera,</em>
      <br />
      <em> cada muro de lo real</em>
      <br />
      <em> se encendía en transparencias misteriosas&#8230;</em>
    </p>
    <p>
      <em>Pero me fui lejos</em>
      <br />
      <em> y ella quedó sola.</em>
      <br />
      <em> Nadie en el circuito inmediato</em>
      <br />
      <em> la veía ni escuchaba.</em>
      <br />
      <em> La dejaron sola.</em>
      <br />
      <em> Tan sola que extravió los caminos</em>
      <br />
      <em> tropezando y cayendo</em>
      <br />
      <em> en hondonadas de terror.</em>
      <br />
      <em> Después lo supe:</em>
      <br />
      <em> ella misma arrojó la finísima copa</em>
      <br />
      <em> y el cristal de su vida estalló en pedazos.</em>
      <br />
      <em> &#8230;(Y ahora ¿por dónde errarás?</em>
      <br />
      <em> ¿en qué increíble estado?</em>
      <br />
      <em> ¿Sentirás, verás, hallarás algo</em>
      <br />
      <em> de aquello que buscamos?</em>
      <br />
      <em> ¿O irán tus plantas silenciosas</em>
      <br />
      <em> eternamente</em>
      <br />
      <em> por un reino vacío?</em>
      <br />
      <em> No lo se.</em>
      <br />
      <em> Pero si altas potestades lo permiten</em>
      <br />
      <em> talvez pueda alcanzar</em>
      <br />
      <em> fuera del tiempo</em>
      <br />
      <em> el vuelo de tu sombra)&#8230;</em>
    </p>
    <p>
      <em>Desolación.</em>
      <br />
      <em> Ciudad desierta bajo la luna.</em>
      <br />
      <em> La muerte instaló en las puertas</em>
      <br />
      <em> mis mudos centinelas.</em>
      <br />
      <em> Veía deshacerse el rostro de lo bello:</em>
      <br />
      <em> crujiente gris lo ajaba.</em>
      <br />
      <em> Poco a poco se hacía piedra,.</em>
      <br />
      <em> polvo y ceniza,</em>
      <br />
      <em> olvido y nada.</em>
    </p>
    <p>
      <em>Llegó después la razón pura</em>
      <br />
      <em> —bella voz de nieve fresca—</em>
      <br />
      <em> tasando y congelando todo</em>
      <br />
      <em> en una blancura de esencias.</em>
      <br />
      <em> E insinuaba, turbadora:</em>
      <br />
      <em> “Dios conjetural&#8230;</em>
      <br />
      <em> Hechura de Dios por el hombre</em>
      <br />
      <em> a su imagen.</em>
      <br />
      <em> Hipótesis jamás demostrada.</em>
      <br />
      <em> Solo hay materia y razón</em>
      <br />
      <em> y poco más que eso es el hombre.”</em>
    </p>
    <p>
      <em>Esa marea amarga</em>
      <br />
      <em> refluía sin embargo.</em>
      <br />
      <em> Perduraba aún el rescoldo</em>
      <br />
      <em> del viejo camino.</em>
    </p>
    <p>
      <em>Siento que el horizonte se anilla</em>
      <br />
      <em> estrechándose&#8230;</em>
      <br />
      <em> Es cierto:</em>
      <br />
      <em> estamos vivos.</em>
      <br />
      <em> El sol</em>
      <br />
      <em> abre aún más corolas</em>
      <br />
      <em> que las ya frías y secas.</em>
      <br />
      <em> Estamos vivos, sí&#8230;</em>
      <br />
      <em> Pero ¿cuánta llama nos queda todavía?</em>
      <br />
      <em> Esa niebla que se avecina en mi ruta</em>
      <br />
      <em> ¿ es el humo de futuros extintos?</em>
      <br />
      <em> Interrogo en vano a frías pupilas estelares.</em>
      <br />
      <em> ¡Comprender, algún día!</em>
      <br />
      <em> ¡Desplegar certezas como alas!</em>
    </p>
    <p>
      <em>Y ahora escucho.</em>
      <br />
      <em> En remota lejanía</em>
      <br />
      <em> un fragor creciente</em>
      <br />
      <em> atraviesa oscura fronteras:</em>
      <br />
      <em> todo aquello que no es solo razón</em>
      <br />
      <em> llega como alud.</em>
      <br />
      <em> Siento llegar un nuevo aire</em>
      <br />
      <em> Y veo como asciende</em>
      <br />
      <em> desde montañas y bosques</em>
      <br />
      <em> un vaho de incienso brillante</em>
      <br />
      <em> hacia los devas radiosos</em>
      <br />
      <em> y el Olimpo estremecido</em>
      <br />
      <em> del viejo Homero.</em>
      <br />
      <em> Veo reencenderse otros ritos</em>
      <br />
      <em> y llegar antiguos dioses</em>
      <br />
      <em> bañados en la luz de acuario.</em>
      <br />
      <em> Descienden intactos</em>
      <br />
      <em> hacia el hombre que espera.</em>
    </p>
    <p>
      <em>Dioses del silencio.</em>
      <br />
      <em> Dioses del canto y de la danza.</em>
      <br />
      <em> Dioses de pura luz cognoscitiva&#8230;Cascadas vivientes</em>
      <br />
      <em> del Dios que allá en la cima</em>
      <br />
      <em> era inaccesible hielo eterno.</em>
      <br />
      <em> Veo incorporarse la inmensa riqueza</em>
      <br />
      <em> de la humana experiencia.</em>
      <br />
      <em> El pasado llega en olas espesas</em>
      <br />
      <em> que cubren tierra y aire:</em>
      <br />
      <em> Moradas incontables</em>
      <br />
      <em> encaramadas en los siglos.</em>
      <br />
      <em> Humor inmenso</em>
      <br />
      <em> de tantos lenguajes diferentes.</em>
      <br />
      <em> Dorada algarabía</em>
      <br />
      <em> de fiestas y de danzas.</em>
      <br />
      <em> multitudes orantes.</em>
      <br />
      <em> Sinfín de melodías</em>
      <br />
      <em> subiendo a las estrellas.</em>
      <br />
      <em> Orgías tenebrosas</em>
      <br />
      <em> de odio, terrores y matanza.</em>
      <br />
      <em> Veo acercarse</em>
      <br />
      <em> el contorsionado oleaje</em>
      <br />
      <em> de un río de dolo:</em>
      <br />
      <em> cuerpos ya marchitos</em>
      <br />
      <em> de hombres y mujeres</em>
      <br />
      <em> venían, duraban, aguantaban</em>
      <br />
      <em> uy al final se iban de la vida</em>
      <br />
      <em> estrujando en yertas manos</em>
      <br />
      <em> los sueños de gozo y amor</em>
      <br />
      <em> nunca vividos&#8230;</em>
      <br />
      <em> Veo rostros de otra humanidad.</em>
      <br />
      <em> Imágenes de ignotos artistas</em>
      <br />
      <em> en civilizaciones sepultadas.</em>
      <br />
      <em> Nada era olvidado:</em>
      <br />
      <em> la obra que disfrutó de gloria</em>
      <br />
      <em> y la que murió ignorada</em>
      <br />
      <em> en recodos de violencia y de terror.</em>
      <br />
      <em> Todo era recogido</em>
      <br />
      <em> e izado a los cielos</em>
      <br />
      <em> por manos celestes.</em>
      <br />
      <em> Todo redimido</em>
      <br />
      <em> en un sentido último.</em>
      <br />
      <em> Todo el mundo.</em>
      <br />
      <em> Y toda la vida.</em>
      <br />
      <em> Y toda la memoria.</em>
      <br />
      <em> Y toda la nostalgia.</em>
      <br />
      <em> Sólo caía</em>
      <br />
      <em> en la nada eterna</em>
      <br />
      <em> la ceniza</em>
      <br />
      <em> de los ángeles negros.</em>
    </p>
    <p>
      <em>Me siento ahora anclado</em>
      <br />
      <em> en un mirar extático&#8230;</em>
      <br />
      <em> Y como no soy solo visión</em>
      <br />
      <em> Y porque me siento libre</em>
      <br />
      <em> Elijo hablar.</em>
      <br />
      <em> Nombrarte.</em>
      <br />
      <em> Y de algún extraño modo</em>
      <br />
      <em> hacerte humano. Misterio último:</em>
      <br />
      <em> Dime QUÉ eres.</em>
      <br />
      <em> Dime QUIEN eres.</em>
      <br />
      <em> ¿Eres ALGUIEN?</em>
      <br />
      <em> ¿Eres ALGO?</em>
      <br />
      <em> ¿Cómo debo hablarte?</em>
      <br />
      <em> ¿Cómo verte, sentirte?</em>
      <br />
      <em> Interrogamos.</em>
      <br />
      <em> Pero las palabras tienen</em>
      <br />
      <em> nuestra estatura.</em>
      <br />
      <em> Desde su umbral avizoramos</em>
      <br />
      <em> la incierta lejanía,</em>
      <br />
      <em> los cielos que te anillan.</em>
      <br />
      <em> Desde siempre</em>
      <br />
      <em> ejércitos de palabras</em>
      <br />
      <em> te han sitiado</em>
      <br />
      <em> y ahora vemos que te aprisionan</em>
      <br />
      <em> sólo a nosotros.</em>
    </p>
    <p>
      <em>¿Existe una palabra mágica</em>
      <br />
      <em> o talvez la ruta sea música?</em>
      <br />
      <em> Si son palabras</em>
      <br />
      <em> ayúdame a encontrarlas.</em>
      <br />
      <em> Si no existen</em>
      <br />
      <em> enséñame a crearlas.</em>
      <br />
      <em> Si la ruta canta</em>
      <br />
      <em> enciende en mi la música no oída.</em>
      <br />
      <em> ¿Hubo de ella algo</em>
      <br />
      <em> —una gota de tu mar—</em>
      <br />
      <em> en los coros de Parsifal</em>
      <br />
      <em> o en los cantos gregorianos</em>
      <br />
      <em> que flotaban como incienso</em>
      <br />
      <em> en los templos góticos?</em>
      <br />
      <em> No lo se. Pero si eres</em>
      <br />
      <em> la vida de la vida</em>
      <br />
      <em> necesito unirme a ti.</em>
      <br />
      <em> Rudo mesianismo intolerante</em>
      <br />
      <em> siempre te ocultó</em>
      <br />
      <em> con máscaras y títeres</em>
      <br />
      <em> de rótulos divinos.</em>
      <br />
      <em> Larga oscuridad llegó de ellos</em>
      <br />
      <em> a la entraña de la vida.</em>
      <br />
      <em> Pero ahora sabemos que esa sombra</em>
      <br />
      <em> no vino de tu ser.</em>
      <br />
      <em> Fue levantada ante nosotros</em>
      <br />
      <em> para ocultar la belleza</em>
      <br />
      <em> del mundo y de los cuerpos.</em>
      <br />
      <em> Desde entonces</em>
      <br />
      <em> el espíritu abandonó el sexo.</em>
      <br />
      <em> Y en vez del brazo humano</em>
      <br />
      <em> cuyo encontrado oleaje</em>
      <br />
      <em> nos deja siempre</em>
      <br />
      <em> un peldaño más arriba</em>
      <br />
      <em> solo quedó allí</em>
      <br />
      <em> una playa muerta</em>
      <br />
      <em> de charla frívola,</em>
      <br />
      <em> rutina y laxitud.</em>
    </p>
    <p>
      <em>Con refinada alquimia</em>
      <br />
      <em> se inventó un cuerpo</em>
      <br />
      <em> enemigo del alma&#8230;</em>
    </p>
    <p>
      <em>Así enseñaron</em>
      <br />
      <em> los maestros del odio.</em>
      <br />
      <em> Pacientes y tenaces</em>
      <br />
      <em> crearon doctrinas inmutables</em>
      <br />
      <em> con templos y escuelas</em>
      <br />
      <em> de vahos asesinos.</em>
      <br />
      <em> Cercaron su páramo soberbio</em>
      <br />
      <em> con llamas tenebrosas de terror y obediencia,</em>
      <br />
      <em> ¡Oh, eran fuertes!</em>
      <br />
      <em> Eran duros, activos,</em>
      <br />
      <em> sutiles y constantes.</em>
      <br />
      <em> Erigieron un imperio</em>
      <br />
      <em> de losas sepulcrales.</em>
      <br />
      <em> Y aún sus piedras duran.</em>
      <br />
      <em> Pero ya no existe la materia “enemiga”</em>
      <br />
      <em> mi tampoco el espíritu sin ella-</em>
      <br />
      <em> Ya no existe la agonía</em>
      <br />
      <em> de quedar en un puerto desolado</em>
      <br />
      <em> mientras el horizonte esfuma</em>
      <br />
      <em> la nave que nos deja.</em>
      <br />
      <em> Ahora somos</em>
      <br />
      <em> Nave, viaje,</em>
      <br />
      <em> tripulación y porvenir.</em>
    </p>
    <p>
      <em>He buscado, he andado&#8230;</em>
      <br />
      <em> largo, largo tiempo.</em>
      <br />
      <em> Abrí puertas olvidadas,</em>
      <br />
      <em> caminos que ya no eran tales.</em>
      <br />
      <em> Pero al fin</em>
      <br />
      <em> brillaron en mis manos</em>
      <br />
      <em> las claves recónditas.</em>
      <br />
      <em> Salí a cielo abierto.</em>
      <br />
      <em> Y he aquí que habían desaparecido</em>
      <br />
      <em> los soles aciagos.</em>
    </p>
    <p>
      <em>Puedo ahora decir:</em>
      <br />
      <em> “Yo voy en tu ruta.</em>
      <br />
      <em> También es mi viaje tu giro ascendente</em>
      <br />
      <em> en millones de mundos.</em>
      <br />
      <em> De alguna manera</em>
      <br />
      <em> te siento en mi sangre.</em>
      <br />
      <em> De alguna manera</em>
      <br />
      <em> mi tosca materia</em>
      <br />
      <em> mi vida pequeña</em>
      <br />
      <em> se funde en tu ser.”</em>
      <br />
      <em> Puedo también ahora</em>
      <br />
      <em> gritar a mi alma</em>
      <br />
      <em> y decirle que cante.</em>
      <br />
      <em> ¡Canta, danza y exulta!</em>
      <br />
      <em> ¡Pero ahora!, ¡ahora!</em>
      <br />
      <em> Talvez mañana la tierra</em>
      <br />
      <em> sea un fulgor que se pierde</em>
      <br />
      <em> en la nube de estrellas.</em>
    </p>
    <p>
      <em>Amanece.</em>
      <br />
      <em> Y ya no hay Sueño.</em>
      <br />
      <em> Pero sí VISIÓN:</em>
      <br />
      <em> En su último borde</em>
      <br />
      <em> todo cuanto es vida y es mundo</em>
      <br />
      <em> o apenas materia,</em>
      <br />
      <em> detiene su vuelo.</em>
      <br />
      <em> Canta.</em>
      <br />
      <em> E ingresa en la luz.</em>
    </p>
  </div>
);
