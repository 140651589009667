import React, { useState } from "react";

export const LaDanzaPage = () => {
  const [openContent, setOpenContent] = useState(false);

  return (
    <div id="la-danza-page">
      <h3>
        La Danza de Todolella. Memoria, historia y usos políticos de la danza de
        espadas. (2011). Valencia: Instituto Valenciano de la Música. (515
        páginas).
      </h3>
      <h4 className="content" onClick={() => setOpenContent(!openContent)}>
        CONTENIDO
      </h4>
      {openContent && (
        <div className="contenido">
          <h5>Agradecimientos 11</h5>
          <h5>Entrada 15</h5>
          <h5>1. Recordando el pasado imperfecto 21</h5>
          <p>
            1.1. Manuel Membrado Llop, Arabogas : «La soledad es muy triste, ¡xa
            ...!» 22
            <br />
            1.2. Rafael Prats: «De segar de Los Monegros…» 29
            <br />
            1.3. José M. Armengot: «¡Ya ve cómo era la vida!» 31
            <br />
            1.4. Herminia Heras Pérez: «Y ahora la gente hemos abierto los
            ojos…» 34
            <br />
            1.5. Emilio Bayot Aguilar y Miguel Armengot Beana : «¡Las mujeres
            sufrían mucho!» 35
            <br />
            1.6. Rogelio Sorribes Soler y Adoración Miralles Querol: «¡A la
            fuente!» 46
            <br />
            1.7. Miquel Querol Plana, Rei y Ángeles Plana Llop: «…pasábamos
            mucha hambre y miserias» 49
          </p>
          <h5>2. Crónica de la Danza todolellana 63</h5>
          <p>
            2.1. «Els juglars que sonaven lo dia de Sant Bertomeu » 65
            <br />
            2.2. «La Danza… ¡Oh, eso es muy viejo!» 69
            <br />
            2.3. «¡A no dejarlas perder!» 70
            <br />
            2.4. «Adiós, San Cristóbal noble…» 74
            <br />
            2.4.1. Rafael Prats Julián: «Dentro de tres días dan la Danza» 75
            <br />
            2.4.2. Santiago Ripollés Sorribes, Totorro : «¡Los ensayos eran una
            fiesta!» 76
            <br />
            2.4.3. José M. Armengot: «¡Tenemos que hacer una fiesta buena!» 80
            <br />
            2.5. «El tío Cinctorranet trae una estatua más del santo» 86
            <br />
            2.5.1. Ana M. Artola: «Tarareando la música de la Danza, consiguió
            volverla a remontar» 92
            <br />
            2.5.2. Rafael Prats Julián: «Ganó el primer premio de toda España»
            94
            <br />
            2.5.3. José María Armengot: «¡Ya casi no se acordaban!» 96
            <br />
            2.5.4. Miguel Beltrán Sabater, Torreta : «Se hacía por que no se
            perdiera y…¡en paz!» 101
            <br />
            2.5.5. Rogelio Sorribes Soler y Adoración Miralles: «¡Había rabia y
            fuerza!» 104
            <br />
            2.6. Irrumpe la Sección Femenina 107
            <br />
            2.6.1. Amelia Ferrer: «¡Eso es per a homes! » 108
            <br />
            2.6.2. De la «Dansa de Todolella » a «Les Danses guerreres de La
            Todolella » 112
            <br />
            2.6.3. Habla Joan Prades 118
            <br />
            2.6.4. Vicent Sorribes: «Herencia de la Sección Femenina» 121
            <br />
            2.7. La Danza rejuvenece 127
            <br />
            2.7.1. José M. Prats, Miñó , Enrique García Milián, Ferré , y Miguel
            Armengot Carceller, Armens : «La Danza estaba muy mal vista» 128
            <br />
            2.7.2. Miguel Ángel Beltrán Llob, Torreta : «Antes había muchas
            ganas de aprender la Danza» 139
            <br />
            2.7.3. Santiago Armengot Heras: «¡Xa, Rubio, tú tens que tocar la
            dansa! » 140
            <br />
            2.8. Una nueva época para la Danza 141
            <br />
            2.8.1. Vicent Sorribes: «¿Cómo nos van a quitar esto?» 145
            <br />
            2.8.2. Xarrada sobre la Danza 149
            <br />
            2.8.3. Santiago Armengot Heras, Rubio :« Perderse la Danza, ¡eso no
            puede ser!» 156
            <br />
            2.8.4. Hacia una formación duradera 162
            <br />
            2.8.5. Vicent Sorribes: «Nuevos aires para la Danza» 162
          </p>
          <h5>3. Historia de la «honrosa danza de espadas» en España 179</h5>
          <p>
            3.1. Introducción 179
            <br />
            3.2. «… bailaron muy gentilmente haciendo chocar sus espadas» 188
            <br />
            3.3. «Este género de danza es muy antiguo en Europa» 191
            <br />
            3.4. «… una dança de 8 hombres a lo valenciano» 198
            <br />
            3.5. «… ni tiene mujeres esta danza, ni otra cosa que pueda hacerla
            menos digna de la iglesia» 204
            <br />
            3.6. «La honrosa danza de espadas» 222
            <br />
            3.7. Siglo XIX : La DE ausente 231
            <br />
            3.8. Nacionalismos «periféricos» y DE 235
            <br />
            3.8.1. En el País Vasco 235
            <br />
            3.8.2. En Galicia y Cantabria 244
            <br />
            3.8.3. La DE en las regiones castellanohablantes 248
            <br />
            3.9. Política de la diversidad, cambios y vigencia 254
            <br />
            3.10. Nace una DE 263
            <br />
            3.11. Conclusiones 267
          </p>
          <h5>4. Breve historia de la danza de espadas en Europa 271</h5>
          <p>
            4.1. Introducción 271
            <br />
            4.2. Trayectoria histórica y difusión geográfica 272
            <br />
            4.3. Vigencia de la DE desde la segunda guerra mundial 291
            <br />
            4.4. Contexto social 296
            <br />
            4.4.1 Ocasiones 301
            <br />
            4.4.2 Requisitos: espadas, música y atuendo 303
            <br />
            4.4.3. Formaciones y danzantes 307
            <br />
            4.4.4. DE y teatro popular 309
            <br />
            4.5. Figuras coreográficas 314
            <br />
            4.6. Mitos e historia de los orígenes 320
            <br />
            4.6.1. Supervivencias prehistóricas en la DE 320
            <br />
            4.6.2. Origen germano de la DE 333
            <br />
            4.6.3. Invasiones bárbaras, arte ornamental y DE 337
            <br />
            4.6.4. Una ancestral costumbre goda: la elevación del Rey sobre un
            escudo 339
            <br />
            4.6.5. Emergencia de la DE en los gremios alemanes medievales 342
            <br />
            4.6.6. Las danzas guerreras de la Antigu¨edad 344
            <br />
            4.7. Genealogía y usos políticos de la DE 352
            <br />
            4.8. “Nazionalización” de la DE 353
            <br />
            4.9. Figuras, significados míticos y supervivencias 360
            <br />
            4.10. Conclusiones 365
          </p>
          <h5>5. Intermedio: las danzas de paloteo 369</h5>
          <p>
            5.1. Rasgos generales 369
            <br />
            5.2. Trayectoria histórica y difusión geográfica 372
            <br />
            5.3. Recuperación y vigencia 382
            <br />
            5.4. Formación y organización social de los grupos 385
            <br />
            5.5. Requisitos: atuendos, herramientas, músicas 387
            <br />
            5.6. Observaciones sobre la coreografía del paloteo 390
            <br />
            5.7. Orígenes y significados 392
          </p>
          <h5>6. Memoria y modernización 397</h5>
          <p>
            6.1. Memoria 397
            <br />
            6.1.1. La Danza rememorada 400
            <br />
            6.1.2. La Danza recordada 401
            <br />
            6.1.3. La Danza olvidada 403
            <br />
            6.1.4. Memoria e historia de la Danza 406
            <br />
            6.1.5. Memoria, lugar e identidad 409
            <br />
            6.2. Modernidad, tradición, ritual 413
            <br />
            6.2.1. Modernización 421
            <br />
            6.2.2. Separación del tiempo y el espacio 422
            <br />
            6.2.3. Liberación de los contextos de presencia 424
            <br />
            6.2.4. Vaciamiento del contenido ritual e identitario 425
            <br />
            6.2.5. Apropiación descontextualizada 426
            <br />
            6.2.6. Reflexividad 429
          </p>
          <h5>Despedida 435</h5>
          <h5>Anexos 437</h5>
          <p>
            1. Primera página del manuscrito de La pastorada (1772) 437
            <br />
            2. Danzas típicas (1929). Transcripción de José Ripollés Climent,
            Piou 438
            <br />
            3. Mochiganga. Danza de Todolella. Rafael Prats Julián (ca. 1944)
            439
            <br />
            4. Intérpretes veteranos de la Danza (1943-1986) 442
            <br />
            5. Factura de trajes para la Danza (1978) 447
            <br />
            6. «“Les danses guerreres de Todolella”, patrimonio histórico
            cultural» (diario Mediterráneo, Castellón, 15 de marzo de 1989) 448
            <br />
            7. Relación de las ejecuciones entre los años 1985 y 2010 (Vicent
            Sorribes) 449
            <br />
            8. Actuaciones de los danzantes entre los años 1985 y 2010 469
          </p>
          <h5>Referencias bibliográficas 479</h5>
          <h5>Índice onomástico-temático</h5>
          <h5>
            Este libro viene acompañado del DVD La Danza de Todolella. Danzas de
            espadas y palos
          </h5>
        </div>
      )}
      <h4>Entrada</h4>
      <p>
        Oculto entre las sierras del Maestrazgo castellonense se encuentra el
        pueblo de Todolella, de cuyo nombre quizás sólo sus habitantes se
        acordarían si no fuera por su castillo y, sobre todo, por su Danza.
      </p>
      <p>
        Aunque los todolellanos lleven el evento bien inscrito en su memoria,
        vale la pena recordarlo. Cada año, en torno a la fiesta de San
        Bartolomé, el 24 de agosto, a las seis en punto de la tarde, ocho
        danzantes acompañados por, al menos, dos músicos (
        <em>dolçainer y tabaleter</em>) se reúnen en el ayuntamiento para
        ponerse el atuendo propio de la ejecución de la Danza. El toque de la{" "}
        <em>Pujà i baixà de la vila</em> señala el comienzo del desplazamiento
        de la comunidad hacia la iglesia. Al frente marchan músicos y danzantes
        seguidos por el alcalde, los concejales, y, por último, algunos miembros
        de la comunidad. Todos se dirigen a la iglesia, de donde retiran sobre
        peanas las imágenes de San Bartolomé, patrono del pueblo, y de San
        Redento. Se inicia la procesión encabezada por la bandera; detrás de
        ella van<em> els doiçainers</em> ejecutando el <em>Toc de</em>
        <em> processó</em>, seguidos por las filas de hombres portando cirios
        encendidos. Los guiones y los danzantes preceden imágenes de ambos
        santos; detrás, marchan el clero y las autoridades. Como de costumbre,
        las mujeres cierran el cortejo, cantando. No obstante, en vez de
        dirigirse directamente a la plaza Mayor, el cortejo recorre —y así,
        simbólicamente santifica— las callejuelas principales del pueblo.
        Llegados frente a la plaza, las peanas con las estatuas de los santos se
        depositan, algo desplazadas hacia la derecha con respecto al{" "}
        <em>dau (o planet) </em>reservado para los danzantes. Frente de ellos se
        ubican los músicos, mientras las autoridades se colocan bajo el
        cobertizo del ayuntamiento, a la izquierda de los danzantes, en torno a
        los cuales las gentes del pueblo y algunos forasteros van congregándose
        en un círculo amplio. Entretanto, los danzantes han ido ocupando sus
        puestos en el dau y están listos para iniciar la Danza. En la actualidad
        la representación comporta doce <em>ballets</em>, de los cuales el
        último —el <em>Torn</em>— constituye una verdadera danza de espadas
        (DE): con sus caracoles y puentes; tampoco faltan la degollada, la
        resurrección y la elevación del <em>Cap de Dança</em>. Al término de la
        representación de la Danza, resuena nuevamente el
        <em> toc de processó</em> que marca el regreso del cortejo al{" "}
        <em>placet de l’església</em>. Depositadas ya las imágenes de los santos
        en su sitio habitual, termina la procesión. Nuevamente al son de la{" "}
        <em>Pujà i baixà</em> de la vila, danzantes , autoridades y comunidad
        participante inician la vuelta a la plaza Mayor, donde termina la
        ceremonia. Entonces la gente intercambia comentarios de circunstancia,
        particularmente con danzantes y músicos. “¿Nos vemos el año que viene?”
        Las mujeres van regresando a sus casas, mientras los hombres prefieren
        encaminarse al único bar del pueblo. En la plaza, donde sólo quedan
        algunos niños jugando, flota un tenue olor a hachas, mientras en los
        cerros vecinos siguen resonando aún los ecos mezclados de dulzaina,
        tamboril, campanas y voces... Allí seguirán resonando durante un año
        entero, como si el tiempo se hubiera detenido. También la vida en el
        pueblo se detendrá un año más en el triste silencio de la
        rutina...(¡bienvenidos a la cotidianidad!), a la espera de que las voces
        de los todolellanos, y el redoble de las campanas, el sonido del{" "}
        <em>tabalet </em>y de la <em>dolçaina</em>, se animen nuevamente, con
        inexorable regularidad, para homenajear al santo en las próximas fiestas
        patronales. El ciclo se ha cerrado en un círculo perfecto. Ocurrió hace
        un momento. La memoria de las fiestas patronales pasadas —de todas las
        fiestas patronales pasadas— se confunde con los sonidos de las fiestas
        de este año, cuya procesión ritual acaba de terminar...
      </p>
      <p>
        Marcada por un contrapunto de olvidos y rememoraciones, ausencias y
        presencias, la Danza ha ido articulando la vida de los todolellanos
        hasta convertirse en símbolo de su identificación como comunidad. Este
        proceso se ha llevado a cabo en las circunstancias adversas y
        contradictorias que los campesinos del Maestrazgo padecieron durante
        buena parte del siglo XX. Por un lado, la pobreza y el hambre, producto
        de las inclemencias de la guerra civil española, obligaron a los
        campesinos a dedicar su tiempo a las tareas básicas de la subsistencia,
        en sus propios pueblos, o tras haber emigrado. Por otro, el desarrollo
        del turismo, a partir de la década de los sesenta, provocó un proceso de
        secularización de las danzas procesionales. La mayor parte de los
        danzantes se marcharon a vivir en Castellón. Hoy regresan a Todolella en
        las vacaciones y, sobre todo, para ejecutar la Danza en las fiestas
        patronales, una ocasión religiosa convertida ahora en atracción
        turística que se despliega ante ojos extraños. El resto de sus
        actuaciones son espectáculos desvinculados del pueblo y de su origen
        ritual. No obstante, la modernización —que ha ido cambiando la forma de
        vida de todos los pueblos del Maestrazgo desde la segunda mitad del
        siglo XX—, lejos de condenar la Danza a una amnesia progresiva, ha
        contribuido a consolidar su tradición. ¡Un pasado de olvidos ha sido
        prólogo de un presente memorioso!
      </p>
      <p>
        La idea de redactar este libro surgió hace casi dos décadas en la
        <em> xarrada </em>sobre la Danza que tuvo lugar el 18 de agosto de 1992
        en el Hogar del Jubilado de Todolella con motivo del 750 Aniversario de
        la Donación de la Carta Pobla. En dicha reunión se proyectaron antiguas
        fotos de la Danza con la intención de que los asistentes — todolellanos
        y turistas vinculados a ellos por lazos familiares— rememoraran tiempos
        pasados e identificaran a los danzantes de entonces. El resultado de las
        identificaciones y fragmentos de aquel diálogo han sido incorporados a
        este trabajo.
      </p>
      <p>
        Las páginas que siguen se ocupan de la Danza todolellana desde su
        trayectoria histórica tanto escrita como oral, y de sus relaciones con
        danzas del mismo tipo, especialmente con la DE, en otros lugares de
        España y Europa. Hablamos, pues, de la Danza vista de cerca para
        comprender mejor la larga tradición coreográfica que le subyace, vista
        de lejos por los historiadores, y viceversa. El propósito general es
        rescatar del olvido aspectos de la experiencia presente de una danza
        tenaz, la DE, cuya historia remonta al siglo XV. A ello se añade una
        interpretación teórica de los testimonios orales y documentos históricos
        que sirven de conclusión al estudio. No obstante, el núcleo del trabajo
        se halla constituido por las voces de quienes en calidad de danzantes,
        músicos o vecinos de Todolella y amigos han protagonizado, promovido y
        sostenido la Danza. En lugar de hablar por ellos, parece más justo
        invitarlos a que hablen ellos mismos. Los todolellanos no son
        “afónicos.” Tienen voz propia y no necesitan ser representados.
      </p>
      <p>
        Tampoco han necesitado de la escritura para narrar su Danza: su
        escritura genuina ha sido la Danza misma en algunas de cuyas mudanzas se
        han inscrito antiguos mitos y memorias que identifican la comunidad.
        Debemos añadir que, a medida que el interés de los protagonistas
        aumentaba, la concepción del libro fue cambiando.
      </p>
      <p>
        Desde la idea de una etnografía tradicional fue derivando a una especie
        de <em>ornitorrinco </em>de difícil clasificación: a las entrevistas
        transcritas se sumaron crónicas, historia oral, historia escrita,
        reflexión teórica y, por cierto, una galería de fotos que pudo haber
        proliferado sin control, si la razón presupuestaria no hubiera
        intervenido.
      </p>
      <p>
        Los todolellanos son, por lo tanto, los verdaderos autores de estas
        páginas, cuyo primer objetivo es documentar la trayectoria histórica de
        la Danza a partir de las propias voces de sus protagonistas. No es,
        pues, un libro concebido en primer lugar para satisfacer propósitos
        académicos. Su finalidad principal es que los vecinos de Todolella se
        reconozcan en la Danza desde sus palabras como actores de la tradición.
        Más que un texto sobre la Danza de Todolella, es la autorrepresentación
        del pueblo en una de sus actividades socioculturales más significantes.
        Idealmente, su lectura quisiera hacer resonar aún el eco de las voces
        efímeras de quienes contaron, rememorándola, su experiencia de la Danza.
        Leer los testimonios escuchando este eco es, ante todo, un placer local
        y quizás una emoción reservados a los todolellanos, y a sus familiares y
        amigos que emigraron del pueblo o ya nos dejaron para siempre.
      </p>
      <p>
        No obstante, la narración de experiencias y recuerdos, además de
        iluminar vidas que otramente hubieran permanecido en las sombras del
        anonimato, y vicisitudes de la Danza condenadas a la fragilidad de la
        memoria, posee un interés histórico que sólo puede satisfacerse con el
        pasaje de la historia oral a la historia escrita. La historia escrita de
        la Danza es más larga que su memoria oral. Implica una investigación
        histórica y problemas teóricos que, si bien pueden carecer de interés
        inmediato para quien tiene que levantarse todos los días al alba para
        realizar sus tareas agrícolas, esclarecen dimensiones insospechadas de
        la Danza, tales como sus relaciones con la tradición más vasta de las
        danzas particulares que la componen: las de castañuelas, de escudos, de
        palos y de espadas; con ritos y creencias primitivas; con la dialéctica
        entre recuerdo y olvido, tradición y modernidad; en fin, con la
        ejecución ritual y representación de la identidad cultural del pueblo.
      </p>
      <p>
        Salvo omisión involuntaria, la transcripción de cada una de las
        entrevistas ha sido aprobada por los entrevistados. Del mismo modo la
        primera versión de algunos capítulos se dejó en alguna ocasión en el bar
        del pueblo para su revisión por los vecinos interesados en el proyecto.
        Uno de ellos fue don Miguel Beltrán, <em>Torreta </em>(“¿se acuerda
        usted, don Miguel?”). Al verme llegar un día al pueblo me dijo: “Ramón,
        si usted publica lo que escribe allí, ¡pensarán que somos unos
        salvajes!...” A buen entendedor, pocas palabras: el texto se modificó
        debidamente.
      </p>
      <p>
        Aparte de apelar a la tradición oral para sacar a luz el devenir de la
        Danza en los últimos cien años, este libro intenta ser una síntesis
        divulgadora de lo que los documentos escritos nos han dejado sobre la DE
        y de palos en la península Ibérica y otra parte de Europa. En
        particular, hemos tratado de identificar los rasgos generales que
        confieren a la Danza todolellana un aire de familia con danzas de
        espadas en otros lugares de España y Europa. Cada vez que los danzantes
        ejecutan la Danza se hermanan inconscientemente con otros pueblos que
        también desde tiempos remotos han practicado este tipo de danzas.
      </p>
      <p>
        El libro tiene principio, medio y fin, aunque no siempre este orden. Su
        concepción responde a un modo de escritura en que las voces habladas de
        los testigos se imbrican, sin perder su identidad, en un texto que, por
        efecto de la transcripción en palabra escrita, transforma su
        transitoriedad original en permanencia y su singularidad en ocasión para
        que el lector pueda elegir el fragmento que quiera leer, según su
        interés. Aunque la mezcla de voces y registros pueda parecer algo
        caótica —¡como en un restaurante de pueblo después del segundo vaso de
        vino!—, el libro suelta las palabras que ellas ya se buscarán las ramas.
        El lector es, pues, libre de elegir el itinerario de su lectura, sin
        peligro de que este desfase con la linealidad del texto le produzca
        daños psíquicos…
      </p>
      <p>
        Este trabajo es, ante todo, una invitación a la memoria de la Danza —una
        memoria que los todolellanos han sabido rescatar obstinadamente del
        olvido. Espera de la memoria, el olvido, que es su sombra, es también,
        como el silencio, espera y sombra del sonido. Toda vez que la Danza
        había sido olvidada, su olvido fue memoria latente, potencialidad de
        actualización de la memoria en la sonoridad viva de las palabras.
      </p>
      <p>
        La Danza todolellana es metáfora del sueño de ser únicos proyectado en
        la simultaneidad de las generaciones que conviven gracias a la memoria
        oral. A través de sus antiguos danzantes, los todolellanos pueden estar
        unidos, en el recuerdo, con las generaciones que les precedieron y que
        les seguirán. Si han persistido en preservar su Danza del olvido es
        porque han querido rescatarse a sí mismos de la amnesia. Así esculpieron
        un emblema de su identidad en las figuras de una Danza, cuyos rastros se
        pierden en las brumas del mito y cuya luz espeja la esperanza de una
        imposible permanencia, en la que sólo una cosa no habrá: el olvido.
      </p>
      <p>
        Este libro es, de alguna manera, una modesta contribución a la futura
        memoria de la Danza…
      </p>
    </div>
  );
};
